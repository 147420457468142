import { styled } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router';
import { useCompanyById } from '../../queries/useCompanies';
import { useInitKpiMatrix } from './hooks/useInitKpiMatrix';
import { useSaveExternallyExclusions } from './hooks/useSaveExternallyExclusions';
import { useMemo } from 'react';
import { CompanyPageSkeletonLoader } from '../../components/SkeletonLoader/CompanyPage.SkeletonLoader';
import { useIsInScreeningFlow } from './hooks/useIsInScreeningFlow';
import { useWorkflowsByCompanyType } from '../../queries/useWorkflows';
import { EsgCompleteCompany } from './EsgCompleteCompany/EsgCompleteCompany';
import { ScreeningCompany } from './ScreeningCompany';

const Wrapper = styled('div')`
  overflow: hidden;
`;

export const SingleCompany = () => {
  const params = useParams();
  const { isLoading: isLoadingCompany, data: company } = useCompanyById(Number(params.id));
  const { isLoading: isLoadingKpiMatrixData } = useInitKpiMatrix(Number(params.id));
  const { isLoading: isWorkflowLoading } = useWorkflowsByCompanyType(company?.type);

  useSaveExternallyExclusions();

  const isInScreeningFlow = useIsInScreeningFlow();

  const companySections = useMemo(() => {
    if (isInScreeningFlow) return <ScreeningCompany />;

    return <EsgCompleteCompany />;
  }, [isInScreeningFlow]);

  if (!company) return null;

  if (isLoadingCompany || isLoadingKpiMatrixData || isWorkflowLoading)
    return <CompanyPageSkeletonLoader />;

  return (
    <AnimatePresence>
      <Wrapper>{companySections}</Wrapper>
    </AnimatePresence>
  );
};
