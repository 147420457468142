import { ReactComponent as DeleteIcon } from '../../../../../../assets/icons/delete.svg';
import { ReactComponent as DuplicateIcon } from '../../../../../../assets/icons/duplicate.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit.svg';
import { MouseEvent, useCallback, useState } from 'react';
import { ActionsPopupMenu } from '../../../../../../components/ActionsPopupMenu/ActionsPopupMenu';
import { AggregatedReportWidget, QuestionOption, WIDGET_TYPE } from '../../../../../../types';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';
import { WidgetModal } from './WidgetModal';
import { generateId } from '../../../../../../utils/generateId';

const options = [
  {
    id: 'EDIT',
    value: 'Edit Widget',
    icon: <EditIcon />,
  },
  {
    id: 'DUPLICATE',
    value: 'Duplicate Widget',
    icon: <DuplicateIcon />,
  },
  {
    id: 'DELETE',
    value: 'Delete Widget',
    icon: <DeleteIcon />,
  },
];

interface Props {
  widget: AggregatedReportWidget;
}

export const ActionMenu = ({ widget }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);
  const [isWidgetModalOpen, setIsWidgetModalOpen] = useState(false);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const deleteWidget = useCallback(() => {
    if (!aggregatedReport) return;
    setAggregatedReport({
      ...aggregatedReport,
      widgets: aggregatedReport?.widgets?.filter((w) => w.id !== widget.id),
    });
  }, [aggregatedReport, setAggregatedReport, widget.id]);

  const duplicateWidget = useCallback(() => {
    if (!aggregatedReport) return;

    const newWidget = {
      id: generateId(),
      questionId: widget.questionId,
      widgetType: widget.widgetType,
    };

    setAggregatedReport({
      ...aggregatedReport,
      widgets: [...(aggregatedReport?.widgets || []), newWidget],
    });
  }, [aggregatedReport, setAggregatedReport, widget.questionId, widget.widgetType]);

  const onMenuItemClick = useCallback(
    (id: string) => {
      handleCloseMenu();
      if (id === 'EDIT') {
        setIsWidgetModalOpen(true);
        return;
      }
      if (id === 'DUPLICATE') {
        duplicateWidget();
        return;
      }
      if (id === 'DELETE') {
        deleteWidget();
        return;
      }

      throw new Error('Unknown option');
    },
    [deleteWidget, duplicateWidget]
  );

  const handleEdit = useCallback(
    ({
      questionId,
      widgetType,
      questionAnswer,
    }: {
      questionId: string;
      widgetType: WIDGET_TYPE;
      questionAnswer?: QuestionOption;
    }) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        widgets: aggregatedReport?.widgets?.map((w) => {
          if (w.id === widget.id) {
            return {
              ...widget,
              questionId,
              widgetType,
              questionAnswer,
            };
          }

          return w;
        }),
      });
      setIsWidgetModalOpen(false);
    },
    [aggregatedReport, setAggregatedReport, widget]
  );

  return (
    <>
      <ActionsPopupMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        actions={options}
        onClick={(e) => handleClick(e)}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
        onMenuItemClick={onMenuItemClick}
        menuStyles={{
          transform: 'translate(25px, 5px)',
        }}
      />
      {isWidgetModalOpen && (
        <WidgetModal
          isOpen={isWidgetModalOpen}
          onClose={() => setIsWidgetModalOpen(false)}
          title='Edit Widget'
          confirmBtnText='Save Changes'
          handleSubmit={handleEdit}
          widgetQuestionId={widget.questionId}
          widgetType={widget.widgetType}
          widgetAnswer={widget.questionAnswer}
        />
      )}
    </>
  );
};
