import { Button, styled, useTheme } from '@mui/material';
import { Multiselect } from '../../../../../components/Multiselect/Multiselect';
import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../types';
import { useMemo, useState } from 'react';
import { FilterItem } from './FilterItem';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';
import { Typography } from '../../../../../components/Typography/Typography';

const Wrapper = styled('div')`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FilterSelectWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

const filtersOptions: SelectItem[] = [
  { value: 'Issuers', id: 1 },
  { value: 'ESG Risk Level', id: 2 },
  { value: 'ESG Opportunities', id: 3 },
  { value: 'Governance Score', id: 4 },
  { value: 'Type', id: 5 },
  { value: 'Active Position', id: 6 },
  { value: 'Sector', id: 7 },
  { value: 'Geography', id: 8 },
];

const labelToTypeMapper = (label: string) => {
  if (label === 'ESG Risk Level') return AGGREGATED_REPORT_ISSUERS_FILTER.RISK_LEVEL;
  if (label === 'ESG Opportunities') return AGGREGATED_REPORT_ISSUERS_FILTER.OPPORTUNITIES;
  if (label === 'Governance Score') return AGGREGATED_REPORT_ISSUERS_FILTER.GOVERNANCE_SCORE_TYPE;
  if (label === 'Type') return AGGREGATED_REPORT_ISSUERS_FILTER.TYPE;
  if (label === 'Active Position') return AGGREGATED_REPORT_ISSUERS_FILTER.ACTIVE_POSITION;
  if (label === 'Sector') return AGGREGATED_REPORT_ISSUERS_FILTER.SECTOR;
  if (label === 'Geography') return AGGREGATED_REPORT_ISSUERS_FILTER.GEOGRAPHY;
  if (label === 'Issuers') return AGGREGATED_REPORT_ISSUERS_FILTER.COMPANIES;

  return AGGREGATED_REPORT_ISSUERS_FILTER.RISK_LEVEL;
};

export const IssuersFilters = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectItem[]>([]);
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const { colors } = useTheme();
  const removeFilterFromReport = (filterType: AGGREGATED_REPORT_ISSUERS_FILTER) => {
    setAggregatedReport((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        issuerFilters: {
          ...prev?.issuerFilters,
          [filterType]: Array.isArray(prev?.issuerFilters?.[filterType]) ? [] : null,
        },
      } as typeof prev;
    });
  };

  const onChangeSelectedFilters = (val: SelectItem[]) => {
    setSelectedFilters(val as SelectItem[]);

    if (!aggregatedReport?.issuerFilters) return;

    const filtersNotIncluded = Object.keys(aggregatedReport.issuerFilters).filter(
      (key) => !val?.find((item) => labelToTypeMapper(item.value) === key),
      []
    ) as AGGREGATED_REPORT_ISSUERS_FILTER[];

    if (!filtersNotIncluded?.length) return;

    setAggregatedReport((prev) => {
      if (!prev) return prev;

      const updatedIssuerFiltersArray = filtersNotIncluded?.map((key) => {
        return {
          [key]: Array.isArray(prev?.issuerFilters?.[key]) ? [] : null,
        };
      });

      const updatedIssuerFilters = updatedIssuerFiltersArray?.reduce?.((acc, item) => {
        if (!item) return acc;
        return {
          ...acc,
          ...item,
        };
      });

      return {
        ...prev,
        issuerFilters: {
          ...prev?.issuerFilters,
          ...updatedIssuerFilters,
        },
      } as typeof prev;
    });
  };

  const onClearAllFilters = () => {
    setSelectedFilters([]);
    setAggregatedReport((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        issuerFilters: null,
      } as typeof prev;
    });
  };

  const isClearBtnDisabled = useMemo(() => !selectedFilters?.length, [selectedFilters?.length]);

  return (
    <Wrapper>
      <FilterSelectWrapper>
        <Multiselect
          style={{ width: '100%' }}
          containerStyles={{ flex: 1 }}
          options={filtersOptions}
          value={selectedFilters}
          onChange={(_, val) => onChangeSelectedFilters(val as SelectItem[])}
          disablePortal
          optionsName='Filters'
          fieldPlaceholder='Filter By'
          selectedTextMaxWidth='9rem'
        />
        <Button variant='text' onClick={onClearAllFilters} disabled={isClearBtnDisabled}>
          <Typography
            variant='subtitle2'
            color={isClearBtnDisabled ? colors.accent[20] : colors.accent[50]}
            style={{ whiteSpace: 'nowrap' }}
          >
            Clear All
          </Typography>
        </Button>
      </FilterSelectWrapper>
      {selectedFilters?.map((item) => {
        return (
          <FilterItem
            key={item.id}
            issuerFilterLabel={item.value}
            issuerFilterType={labelToTypeMapper(item.value)}
            setSelectedFilters={setSelectedFilters}
            removeFilterFromReport={removeFilterFromReport}
          />
        );
      })}
    </Wrapper>
  );
};
