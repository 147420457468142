import { styled, useTheme } from '@mui/material';
import { EsgCompleteCompanyContent } from './EsgCompleteCompanyContent/EsgCompleteCompanyContent';
import { motion, useAnimation } from 'framer-motion';
import { useState } from 'react';
import { SingleCompanyHeader } from '../../../components/SingleCompanyHeader';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { ActivityDrawer } from './ActivityDrawer/ActivityDrawer';
import { useCompanyReports } from '../hooks/useCompanyReports';
import { ROUTES } from '../../../constants/routes';
import { AssessmentsTable } from '../components/AssessmentsTable';
import { ResponsesTable } from '../components/ResponsesTable';

const ACTIVITY_DRAWER_WIDTH = 432;

const Wrapper = styled('div')`
  display: flex;
  height: 100vh;
  position: relative;
`;

const Content = styled(motion.div)`
  flex: 1;
  overflow: auto;
  padding-right: 0px;
`;

const DrawerWrapper = styled(motion.div)`
  position: absolute;
  height: 100vh;
  border-left: none;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  display: flex;
  flex-direction: column;
  z-index: 99;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 0px;
`;
export const EsgCompleteCompany = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);

  const controls = useAnimation();
  const drawerControls = useAnimation();
  const { colors } = useTheme();

  const { assessmentReports, nonAssessmentReports } = useCompanyReports();

  const onCloseActivityDrawer = () => {
    setIsActivityDrawerOpen(false);
    controls.start({
      paddingRight: '0px',
      transition: { duration: 0.3 },
    });

    drawerControls.start({
      width: '0px',
      borderLeft: 'none',
      transition: { duration: 0.3 },
    });
  };

  const onToggleActivityDrawer = () => {
    if (isActivityDrawerOpen) {
      onCloseActivityDrawer();
      return;
    }

    setIsActivityDrawerOpen(true);

    controls.start({
      paddingRight: `${ACTIVITY_DRAWER_WIDTH}px`,
      transition: { duration: 0.3 },
    });

    drawerControls.start({
      width: `${ACTIVITY_DRAWER_WIDTH}px`,
      borderLeft: `1px solid ${colors.primary[30]}`,
      transition: { duration: 0.3 },
    });
  };

  if (!company) return null;

  return (
    <Wrapper>
      <Content animate={controls}>
        <SingleCompanyHeader
          company={company}
          isActivityDrawerOpen={isActivityDrawerOpen}
          onToggleActivityDrawer={onToggleActivityDrawer}
        />

        <DrawerWrapper animate={drawerControls}>
          <ActivityDrawer onClose={onCloseActivityDrawer} />
        </DrawerWrapper>
        <EsgCompleteCompanyContent />
        {Boolean(
          assessmentReports?.length &&
            params.activeTab !== ROUTES.COMPANY_PERFORMANCE &&
            params.activeTab !== ROUTES.COMPANY_CHECKLIST
        ) && <AssessmentsTable reports={assessmentReports || []} />}
        {Boolean(
          nonAssessmentReports?.length &&
            params.activeTab !== ROUTES.COMPANY_PERFORMANCE &&
            params.activeTab !== ROUTES.COMPANY_CHECKLIST
        ) && <ResponsesTable reports={nonAssessmentReports || []} />}
      </Content>
    </Wrapper>
  );
};
