import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { BarLineChart } from '../../../../../../components/Charts/BarCharts/BarLineChart';
import { AggregatedReportWidget, QUESTION_TYPE, QuestionOption } from '../../../../../../types';
import { useMemo } from 'react';
import { useBreakdownData } from '../../../../hooks/useBreakdownData';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { useWidgetQuestion } from '../hooks/useWidgetQuestion';
import { useCompaniesAnswers } from '../hooks/useCompaniesAnswers';
import { WidgetCard } from './WidgetCard';
import { NO_ANSWER_OPTION } from '../constants';
import { useWidgetChartColor } from '../hooks/useWidgetChartColor';
import { styled } from '@mui/material';

interface Props {
  widget: AggregatedReportWidget;
}

const Wrapper = styled('div')`
  flex: 1;
  min-width: 400px;
`;

export const BarChartWidget = ({ widget }: Props) => {
  const { filteredCompanies } = useBreakdownData();
  const getWidgetChartColor = useWidgetChartColor();

  const widgetQuestion = useWidgetQuestion(widget);

  const { companiesAnswers } = useCompaniesAnswers(widget);

  const widgetData = useMemo(() => {
    const questionAnswers = [...(widgetQuestion?.options || []), NO_ANSWER_OPTION];

    return questionAnswers?.map((answer: QuestionOption) => {
      let numOfAnswers = 0;

      if (widgetQuestion?.type === QUESTION_TYPE.SINGLE) {
        numOfAnswers = companiesAnswers?.filter((companyAnswer) => {
          return (companyAnswer?.answer as QuestionOption)?.id === answer?.id;
        }).length;
      }

      if (widgetQuestion?.type === QUESTION_TYPE.MULTI) {
        numOfAnswers = companiesAnswers?.filter((companyAnswer) => {
          return (companyAnswer?.answer as QuestionOption[])?.find((a) => a?.id === answer?.id);
        }).length;
      }

      return {
        id: answer.id,
        name: answer.name,
        value: numOfAnswers,
      };
    });
  }, [companiesAnswers, widgetQuestion?.options, widgetQuestion?.type]);

  const series: BarSeriesOption | LineSeriesOption = useMemo(() => {
    const formatter = (params: CallbackDataParams) => {
      const { value } = (params as CallbackDataParams).data as { value: number };
      const pctValue = value
        ? `${(((value as number) / filteredCompanies?.length) * 100).toFixed(2)}%`
        : '0%';
      return pctValue;
    };

    return {
      type: 'bar',
      colorBy: 'data',
      data:
        widgetData?.map((widget, i) => ({
          value: widget?.value,
          itemStyle: { color: getWidgetChartColor(widgetData, i) },
        })) || [],
      label: {
        show: true,
        position: 'top',
        formatter,
        fontSize: 14,
        fontWeight: 600,
      },
    };
  }, [filteredCompanies?.length, getWidgetChartColor, widgetData]);

  const yAxisOptions = {
    max: function (value: { max: number }) {
      return value.max + 1;
    },
  };

  if (!widgetQuestion) return null;

  return (
    <Wrapper>
      <WidgetCard widget={widget} titleContainerStyles={{ flexDirection: 'column', gap: '3px' }}>
        <BarLineChart
          seriesData={[series]}
          xAxisData={widgetData?.map((d) => d?.name)}
          yAxisOptions={yAxisOptions}
        />
      </WidgetCard>
    </Wrapper>
  );
};
