import { styled } from '@mui/material';

import { FilterButton } from './FilterButton';
import { useAtom } from 'jotai';
import { aggregatedReportFilterTypeState, aggregatedReportState } from '../../../../state/UIState';
import { AGGREGATED_REPORT_FILTER } from '../../../../types';
import { useBreakdownData } from '../../hooks/useBreakdownData';
import { useMemo } from 'react';

const Wrapper = styled('div')`
  padding: 7px 40px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  display: flex;
`;

export const FiltersBar = () => {
  const [filterType, setFilterType] = useAtom(aggregatedReportFilterTypeState);
  const { selectedQuestions, groupedBy } = useBreakdownData();
  const [aggregatedReport] = useAtom(aggregatedReportState);

  const onToggleFilterDrawer = (type: AGGREGATED_REPORT_FILTER) => {
    if (filterType === type) {
      setFilterType(null);
      return;
    }
    setFilterType(type);
  };

  const filtersNum = useMemo(() => {
    let filtersNum = 0;

    Object.values(aggregatedReport?.issuerFilters || {}).forEach((filters) => {
      if (Array.isArray(filters) && Boolean(filters.length)) {
        filtersNum += filters.length;
        return;
      }
      if (!Array.isArray(filters) && filters) {
        filtersNum += 1;
      }
    });

    return filtersNum;
  }, [aggregatedReport?.issuerFilters]);

  return (
    <Wrapper>
      <FilterButton
        label='Questions'
        badgeNum={selectedQuestions?.length}
        onClick={() => onToggleFilterDrawer(AGGREGATED_REPORT_FILTER.QUESTIONS)}
      />
      <FilterButton
        label='Filters'
        badgeNum={filtersNum}
        onClick={() => onToggleFilterDrawer(AGGREGATED_REPORT_FILTER.ISSUERS)}
      />
      <FilterButton
        label='Groups'
        badgeNum={groupedBy?.length || 0}
        onClick={() => onToggleFilterDrawer(AGGREGATED_REPORT_FILTER.GROUPS)}
      />
    </Wrapper>
  );
};
