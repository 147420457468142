import { IconButton, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-gray.svg';
import { ActivityFilters } from './components/ActivityFilters';
import { Activities } from './components/Activites/Activities';
import { AddNote } from './components/AddNote';
import { useMemo, useState } from 'react';
import { ActivityResult, CompanyActivity, SelectItem } from '../../../../types';
import { useCompanyActivity } from '../../../../queries/useCompanyActivity';
import { useParams } from 'react-router';
import { formatDate } from '../../../../utils/formatters';

const Wrapper = styled('div')`
  padding: 16px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  onClose: () => void;
}

const getUsersFromCurrentGroup = (group: CompanyActivity[]) => {
  return group.map((item) => item.user || item.externalUser)?.filter((item) => Boolean(item)) || [];
};

export const ActivityDrawer = ({ onClose }: Props) => {
  const { colors } = useTheme();
  const params = useParams();

  const [selectedUsers, setSelectedUsers] = useState<SelectItem[]>([]);
  const [selectedActivities, setSelectedActivities] = useState<SelectItem[]>([]);

  const {
    data: activities,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useCompanyActivity(Number(params.id), {
    users: selectedUsers.map((user) => user.id as number)?.join(','),
    subtypes: selectedActivities.map((activity) => activity.value)?.join(','),
  });

  console.log('isLoading', isLoading);
  console.log('isFetching', isFetchingNextPage);

  const activitiesData = useMemo(
    () => activities?.pages?.flatMap((page) => page.data),
    [activities?.pages]
  );

  const groupedActivities = useMemo(() => {
    if (!activitiesData?.length) return [];

    const result: ActivityResult[] = [];
    let currentGroup = [activitiesData[0]];

    activitiesData?.forEach((activity, i) => {
      if (i === 0) return;

      if (
        activity?.type === currentGroup[0].type &&
        formatDate(activity.created_at) === formatDate(currentGroup[0].created_at)
      ) {
        currentGroup.push(activity);
        return;
      }
      if (currentGroup.length > 1) {
        const users = getUsersFromCurrentGroup(currentGroup);

        result.push({
          type: currentGroup[0].type,
          created_at: currentGroup[0].created_at,
          items: [...currentGroup],
          users: [...users],
        });
      } else {
        result.push(currentGroup[0]);
      }
      currentGroup = [activity];
    });

    if (currentGroup.length > 1) {
      const users = getUsersFromCurrentGroup(currentGroup);
      result.push({
        type: currentGroup[0].type,
        items: [...currentGroup],
        created_at: currentGroup[0].created_at,
        users: [...users],
      });
    } else {
      result.push(currentGroup[0]);
    }

    return result;
  }, [activitiesData]);

  return (
    <Wrapper>
      <Header>
        <Typography variant='h4' color={colors.primary[90]} style={{ margin: '8px 0 0 8px' }}>
          Activity
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Header>
      <ActivityFilters
        setSelectedUsers={setSelectedUsers}
        selectedUsers={selectedUsers}
        selectedActivities={selectedActivities}
        setSelectedActivities={setSelectedActivities}
      />
      <Activities
        activities={groupedActivities}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
      />
      <AddNote />
    </Wrapper>
  );
};
