import { MenuItem, SelectChangeEvent, styled, useTheme } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as YesAnswer } from '../../../../../assets/icons/checkmark.svg';
import { ReactComponent as NAIcon } from '../../../../../assets/icons/not-available.svg';
import { ReactComponent as NoAnswer } from '../../../../../assets/icons/no-answer.svg';
import {
  ACTIVITY_TYPE,
  CHECKLIST_ITEM_FREQUENCY,
  CHECKLIST_OPTION,
  CHECKLIST_SUBTYPE,
  ChecklistDataItem,
} from '../../../../../types';
import { SelectItem } from './SelectItem';
import { NotesBoxPopup } from '../../../../../components/NotesBoxPopup/NotesBoxPopup';
import { useAtom, useAtomValue } from 'jotai';
import { companyChecklistDataState, userState } from '../../../../../state/UIState';
import { Typography } from '../../../../../components/Typography/Typography';
import { BasicSelect } from '../../../../../components/BasicSelect/BasicSelect';
import { useCreateCompanyActivity } from '../../../../../queries/useCompanyActivity';
import { useParams } from 'react-router';

const Container = styled('div')<{ isOpen: boolean }>`
  width: 100%;
  background: ${({ theme, isOpen }) => {
    if (isOpen) {
      return theme.colors.gray[20];
    }

    return 'transparent';
  }};
  min-height: 36px;
  border-radius: 2px;
  display: flex;
  align-items: center;

  & .MuiInputBase-root.MuiOutlinedInput-root {
    border: none;
    width: 100%;
    padding-left: 4px;
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  & .MuiOutlinedInput-root .MuiSelect-select {
    padding: 0;
    border-radius: 0;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

interface Props {
  value: CHECKLIST_OPTION;
  notes?: string;
  itemId: string;
  frequency: CHECKLIST_ITEM_FREQUENCY;
}

type TMenuItem = {
  name: CHECKLIST_OPTION;
  icon: JSX.Element;
};

export const AnswerSelectCell = ({ value, notes, itemId, frequency }: Props) => {
  const params = useParams();
  const [selectedOption, setSelectedOption] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const [companyChecklistData, setCompanyChecklistData] = useAtom(companyChecklistDataState);
  const user = useAtomValue(userState);
  const { mutate: createCompanyActivity } = useCreateCompanyActivity(Number(params.id));

  const { colors } = useTheme();

  const checklistOptions: TMenuItem[] = [
    { name: CHECKLIST_OPTION.YES, icon: <YesAnswer /> },
    { name: CHECKLIST_OPTION.NO, icon: <NoAnswer /> },
    { name: CHECKLIST_OPTION.NA, icon: <NAIcon /> },
  ];

  const onChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as CHECKLIST_OPTION);

    if (!companyChecklistData) return;

    const newChecklistData = {
      ...companyChecklistData,
      [frequency]: companyChecklistData[frequency].map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            checklistOption: {
              name: event.target.value,
              updatedPeriod: new Date().toISOString(),
            },
            notes: '',
          } as ChecklistDataItem;
        }

        return item;
      }),
    };

    setCompanyChecklistData(newChecklistData);

    const checklistItem = newChecklistData[frequency].find(
      (item) => item.id === itemId
    ) as ChecklistDataItem;

    if (checklistItem && event.target.value === CHECKLIST_OPTION.YES && user) {
      createCompanyActivity({
        company: Number(params.id),
        user: user?.id,
        type: ACTIVITY_TYPE.CHECKLIST,
        subtype: CHECKLIST_SUBTYPE.CHECKLIST_ITEM_COMPLETED,
        value: {
          checklistItem,
        },
      });
    }
  };

  return (
    <NotesBoxPopup notes={notes || ''} popupStrategy='fixed' containerStyle={{ height: '100%' }}>
      <Container isOpen={isOpen}>
        <BasicSelect
          value={selectedOption}
          onChange={onChange}
          setIsOpen={setIsOpen}
          renderValue={(selected) => {
            const selectedItem = checklistOptions.find((option) => selected === option.name);
            if (!selectedItem)
              return (
                <Typography variant={'subtitle2'} color={colors.accent[50]}>
                  Select an option
                </Typography>
              );
            return (
              <SelectItem
                icon={selectedItem?.icon}
                checklistOption={selectedItem.name}
                isDropdownOpen={isOpen}
                hasNotes={Boolean(notes)}
              />
            );
          }}
        >
          {checklistOptions.map((checklistOption) => (
            <MenuItem key={checklistOption.name} value={checklistOption.name}>
              <SelectItem icon={checklistOption.icon} checklistOption={checklistOption.name} />
            </MenuItem>
          ))}
        </BasicSelect>
      </Container>
    </NotesBoxPopup>
  );
};
